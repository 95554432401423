import React from "react";
import tw, { styled } from "twin.macro";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Header from "@shared/components/Header";
import { MAIN_COLOR, SITE } from "../config";

const Container = styled.div`
  ${tw`p-5`}
`;
const Content = styled.div`
  ${tw`
    w-full mt-5 
    flex flex-col md:flex-row gap-5
  `}
`;
const Location = styled.div`
  ${tw`w-full flex-1`}
`;
const Address = styled.a`
  ${tw`block mt-5 font-semibold hover:text-[#EC1F27]`}
`;
const MarkdownDiv = styled.div`
  ${tw`prose max-w-full flex-1`}
`;
const GatsbyImageContainer = styled.div`
  ${tw`w-full`}
`;

const DirectionPage = ({
  data: {
    markdownRemark: {
      html,
      frontmatter: {
        seo,
        mapImage,
        title,
        address: { label, url },
      },
    },
  },
  location,
}) => {
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && <Header title={title} color={MAIN_COLOR} site={SITE} />}
        <Content>
          <Location>
            {mapImage && (
              <GatsbyImageContainer>
                <GatsbyImage image={getImage(mapImage)} alt="map_image" />
              </GatsbyImageContainer>
            )}
            <Address href={url} target="_blank">
              {label}
            </Address>
          </Location>
          <MarkdownDiv dangerouslySetInnerHTML={{ __html: html }} />
        </Content>
      </Container>
    </Layout>
  );
};

export default DirectionPage;

export const pageQuery = graphql`
  query DirectionPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        seo {
          title
          description
        }
        mapImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title
        address {
          label
          url
        }
      }
    }
  }
`;
